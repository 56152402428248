import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    introBox,
    drinksList,
    secretList,
    drinksBox
} from './lemon-page.module.scss';

import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IProductPreview } from '../models/product-preview.model';
import {ImageDataLike} from "../models/image-data-like.model";
import { ISitePageContext } from '../models/site-page.model';

import {getSecrets} from "../utils/get-secrets";
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import BackgroundRepeat from "../layouts/background-repeat";
import ProductList from '../components/organisms/product-list';
import PageIntro from '../components/organisms/page-intro';
import SecretList from "../components/organisms/secret-list";


interface ILemonPageProps {
    readonly data: {
        page: IPage | null;
        allSecretImg: IQueryAllResult<ImageDataLike>;
        allStrapiOldRecipe: IQueryAllResult<IProductPreview>;
    };
    readonly pageContext: ISitePageContext;
}

const LemonPage: React.FC<ILemonPageProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { page, allStrapiOldRecipe, allSecretImg } = data;
    const { site } = pageContext;
    const secretImages = getNodes(allSecretImg);
    const secrets = getSecrets(secretImages, 'flavored.secret.item.title', t, "lemon");
    const recipes = getNodes(allStrapiOldRecipe);
    const { introContent } = page || {};

    return (
        <MainLayout page={page} className={layout} siteKey={site}>
            <div className={introBox}>
                <PageIntro title={t("layout.page.intro.title")} subTitle={t("lemon.page.intro.subtitle")} description={introContent} />
                <SecretList
                    className={secretList}
                    secrets={secrets}
                    isGoldColor={true}
                />
            </div>
            <BackgroundRepeat className={drinksBox}>
                <ProductList className={drinksList} recipes={recipes} />
            </BackgroundRepeat>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
        
        allSecretImg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "flavored-secret-05.png"
                        "flavored-secret-07.png"
                        "flavored-secret-13.png"
                        "flavored-secret-14.png"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }

        allStrapiOldRecipe(
            filter: {
                locale: { eq: $language }
                showIn: { eq: $site }
                isDummyContent: { eq: false }
            }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...oldRecipeFields
                }
            }
        }
    }
`;

export default LemonPage;
