import React from "react";
import {getImage} from "gatsby-plugin-image";

import { container, imgBox, ratio, titleText, itemsNumbers } from './secret-item.module.scss';
import { ISecret } from "../../models/secret.model";

import RatioImage from "../atoms/ratio-image";

interface ISecretItemProps {
    className?: string;
    secret: ISecret;
    secretItemNumbers?: boolean;
    index?:number;
}

const SecretItem: React.FC<ISecretItemProps> = ({
    className = '',
    secret,
    secretItemNumbers,
    index
}) => {
    const { title, image } = secret;

    return (
        <div className={`${container} ${className}`}>
            <RatioImage
                image={getImage(image)}
                ratioClass={ratio}
                className={imgBox}
                objectFit="scale-down"
            />
            {secretItemNumbers && <p className={itemsNumbers}>{index}</p>}
            <p className={titleText}>
                {title}
            </p>
        </div>
    );
};

export default SecretItem;