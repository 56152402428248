import React from "react";

import { list, gold, threeElems } from './secret-list.module.scss';

import { ISecret } from "../../models/secret.model";

import SecretItem from "../molecules/secret-item";

interface ISecretListProps {
    className?: string;
    secrets: ISecret[];
    isGoldColor?: boolean;
    threeElem?:boolean;
    secretItemNumbers?: boolean;
}

const SecretList: React.FC<ISecretListProps> = ({
    className = '',
    secrets,
    isGoldColor = false,
    threeElem,
    secretItemNumbers
}) => {
    return (
        <div className={className}>
            <ul className={`${list} ${threeElem && threeElems} ${isGoldColor ? gold : ''}`}>
                {secrets?.map((secret, index) => {
                    return (
                        <li key={`secret-item-${index}`}>
                            <SecretItem secret={secret} secretItemNumbers={secretItemNumbers} index={index + 1} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SecretList;