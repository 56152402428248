import { ImageDataLike } from "../models/image-data-like.model";
import { TFunction } from "react-i18next";
import { ISecret } from "../models/secret.model";

export function getSecrets(
    secretImages: ImageDataLike[],
    translationKey: string,
    t: TFunction<string | string[]>,
    type?:string,
): ISecret[] {
    return secretImages?.map((image, index) => {
        return {
            title: t(`${translationKey}${type && index == 1 ? `.${type}.${index}` : `.${index}` }`),
            image: image,
        }
    })
}