import React from 'react';

import { list } from './product-list.module.scss';
import { IProductPreview } from '../../models/product-preview.model';
import { ITitleProps } from '../atoms/title';

import ProductPreview, {IProductPreviewProps} from '../molecules/product-preview';


interface IProductListProps {
    className?: string;
    recipes: IProductPreview[];
    titleProps?: Omit<ITitleProps, 'className'>;
    showAbsoluteImage?: boolean;
    productPreviewProps?: Pick<IProductPreviewProps, 'className' | 'contentBoxClassName'>;
}

const ProductList: React.FC<IProductListProps> = ({ className = '', recipes, titleProps, productPreviewProps}) => {
    return (
        <ul className={`${list} ${className}`}>
            {recipes.map((recipe, index) => {
                const isReverse = index % 2 > 0;
                return (
                    <li key={`product-drink-item-${index}`}>
                        <ProductPreview
                            titleProps={titleProps}
                            isReverse={isReverse}
                            recipe={recipe}
                            {...productPreviewProps}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

export default ProductList;
